import React, { useState, useRef } from "react"
import "./MainPage.css"
import { Link } from "react-router-dom"
import { CiStar } from "react-icons/ci"
import { FaViber, FaPhoneAlt } from "react-icons/fa"
import { IoLocationOutline } from "react-icons/io5"
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io"
import { MuiTelInput } from "mui-tel-input"
import TextField from "@mui/material/TextField"
import Carousel from "react-material-ui-carousel"
import { BiSolidLike } from "react-icons/bi"
import { Typography, Box, Grid, Button, IconButton, Card, CardContent, Icon } from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import CloseIcon from "@mui/icons-material/Close"
import { Paper } from "@mui/material"
import { ThumbUp, Timer, Extension, ChatBubbleOutline, RateReview, TrendingUp } from "@mui/icons-material"
import image1 from "../images/qr1.jpeg"
import image9 from "../images/qr9.jpeg"
import image10 from "../images/qr10.jpeg"
import image11 from "../images/qr11.jpeg"
import image12 from "../images/qr12.jpeg"
import image13 from "../images/qr13.jpeg"
import image14 from "../images/qr14.jpeg"
import image15 from "../images/qr15.jpeg"
import whiteWidget from "../images/white-wdiget.png"
import blackWidget from "../images/black-widget.png"
import axios from "axios"

const MainPage = () => {
	const [showBanner, setShowBanner] = useState(true)
	const [name, setName] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")

	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [snackbarSeverity, setSnackbarSeverity] = useState("success") // 'success' or 'error'
	const [snackbarMessage, setSnackbarMessage] = useState("")

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}
		setSnackbarOpen(false)
	}

	const handleSubmit = async () => {
		if (!name || !phoneNumber) {
			setSnackbarSeverity("error")
			setSnackbarMessage("Пожалуйста, заполните все поля.")
			setSnackbarOpen(true)
			return
		}

		function getCSRFToken() {
			const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
			return csrfCookie ? csrfCookie[1] : null
		}

		try {
			// Make a POST request using axios (adjust the URL accordingly)
			const response = await axios.post(
				"/api/users/send_application/",
				{
					name: name,
					phoneNumber: phoneNumber,
				},
				{ headers: { "X-CSRFToken": getCSRFToken() } }
			)

			// Clear the form fields
			setName("")
			setPhoneNumber("")

			// Display success notification
			setSnackbarSeverity("success")
			setSnackbarMessage("Заявка отправлена успешно!")
			setSnackbarOpen(true)
		} catch (error) {
			// Display error notification
			setSnackbarSeverity("error")
			setSnackbarMessage("Не удалось отправить заявку. Пожалуйста, повторите попытку позже.")
			setSnackbarOpen(true)
		}
	}

	const closeBanner = () => {
		setShowBanner(false)
	}

	const handleChangeName = event => {
		setName(event.target.value)
	}

	const handleChangePhoneNumber = newValue => {
		setPhoneNumber(newValue)
	}

	const images = [image1, image9, image10, image11, image12, image13, image14, image15]

	const stats = [
		{ label: "Частота оставления отзывов", description: "В среднем, клиенты оставляют отзывы на 72% чаще, чем без использования нашего сервиса", value: 72 },
		{ label: "Перехвачено негативных отзывов", description: "Негативные отзывы, которые могли оказаться в интернете купировались. Проблема решались напрямую с клиентом", value: 87 },
		{ label: "Отзывы в нескольких местах", description: "Довольный клиент оставляет хороший отзыв сразу в нескольких местах, например, на Яндекс.Картах, 2ГИС и Вконтакте", value: 47 },
		{ label: "Рост трафика", description: "Благодаря активности и увеличению рейтинга, алгоритмы повышают позиции для отображения, что существенно увеличивает трафик и отстройку от конкурентов", value: 64 },
	]

	const reasons = [
		{ title: "Увеличить свой рейтинг", description: "Сервис стимулирует клиента оставлять положительные отзывы и отсеивает негатив", icon: <ThumbUp />, gradient: "linear-gradient(45deg, #a1ffce, #faffd1)", size: { xs: 12, sm: 6, md: 4 } },
		{ title: "Экономить время и беречь нервы", description: "Не нужно просить человека менять отзыв на хороший после решения спорной ситуации", icon: <Timer />, gradient: "linear-gradient(45deg, #accbee, #e7f0fd)", size: { xs: 12, sm: 6, md: 4 } },
		{ title: "Использовать все возможности", description: "В сервисе в одном месте собраны все площадки для отзывов. Собирайте отзывы туда, куда нужно", icon: <Extension />, gradient: "linear-gradient(45deg, #fbc2eb, #a6c1ee)", size: { xs: 12, sm: 6, md: 4 } },
		{ title: "Быстро решать спорные ситуации", description: "Клиент не выльет негатив туда, куда это не нужно, негативный отзыв увидите только вы, и сможете уладить ситуацию напрямую", icon: <ChatBubbleOutline />, gradient: "linear-gradient(45deg, #ffecd2, #fcb69f)", size: { xs: 12, sm: 6, md: 4 } },
		{ title: "Упростить сбор отзывов", description: "Не нужно объяснять, что и как делать, чтобы оставить отзыв. Сервис сам приведет клиента на форму", icon: <RateReview />, gradient: "linear-gradient(45deg, #e0c3fc, #8ec5fc)", size: { xs: 12, sm: 6, md: 4 } },
		{ title: "Повысить трафик и опередить конкурентов", description: "За счет активности клиентов и роста рейтинга увеличивается посещаемость ваших ресурсов", icon: <TrendingUp />, gradient: "linear-gradient(45deg, #ff9a9e, #fad0c4)", size: { xs: 12, sm: 6, md: 4 } },
	]

	function CircularProgressWithRoundCaps({ value, size, thickness }) {
		const radius = (size - thickness) / 2
		const circumference = 2 * Math.PI * radius
		const offset = ((100 - value) / 100) * circumference

		return (
			<Box
				sx={{
					position: "relative",
					display: "inline-flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<svg width={size} height={size}>
					{/* Background circle */}
					<circle cx={size / 2} cy={size / 2} r={radius} fill="transparent" stroke="#EDEDED" strokeWidth={thickness} />
					{/* Foreground circle */}
					<circle
						cx={size / 2}
						cy={size / 2}
						r={radius}
						fill="transparent"
						stroke="#3B73F5"
						strokeWidth={thickness}
						strokeDasharray={circumference}
						strokeDashoffset={offset}
						strokeLinecap="round" // This creates the rounded stroke caps
						transform={`rotate(-90 ${size / 2} ${size / 2})`}
					/>
				</svg>
				<Typography
					variant="h4"
					component="div"
					color="text.secondary"
					sx={{
						position: "absolute",
					}}
				>
					{`${value}%`}
				</Typography>
			</Box>
		)
	}

	const scrollToContactForm = () => {
		const form = document.getElementById("contact-form")
		if (form) {
			try {
				form.scrollIntoView({ behavior: "smooth" })
			} catch (error) {
				console.error("Error with smooth scroll: ", error)
			}
		} else {
			console.log("Element with ID `contact-form` not found.")
		}
	}

	function openTelegramBot() {
		window.open("https://t.me/otzoprobot", "_blank")
	}

	return (
		<div className="main-page">
			{showBanner && (
				<Box
					sx={{
						position: "fixed",
						top: showBanner ? 0 : "-100%", // Изменение верхнего отступа для плавного появления/исчезновения
						left: 0,
						right: 0,
						display: { xs: "none", sm: "flex" },
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#3B73F5",
						color: "white",
						padding: "10px 20px",
						zIndex: 1000,
						transition: "top 0.5s", // Плавная транзиция для свойства top
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<Typography variant="h6" sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
							⚡ ЕЩЁ БОЛЬШЕ ПОЛОЖИТЕЛЬНЫХ ОТЗЫВОВ С OTZO.PRO ⚡
						</Typography>
						<Button
							variant="contained"
							onClick={scrollToContactForm}
							sx={{
								backgroundColor: "white", // Фон кнопки белый
								color: "#3B73F5", // Цвет текста кнопки синий
								borderRadius: "50px",
								"&:hover": {
									backgroundColor: "#f0f0f0", // Цвет фона кнопки при наведении слегка серый
								},
								// Вы можете добавить boxShadow: 'none' если не хотите тени у кнопки
							}}
						>
							ЗАКАЗАТЬ
						</Button>
					</Box>
					<IconButton onClick={closeBanner} sx={{ position: "absolute", right: 8, top: 8, color: "white" }}>
						<CloseIcon />
					</IconButton>
				</Box>
			)}
			<div className="welcome">
				<div className="welcome-overlay">
					<div className="welcome-content">
						<h1>OTZO.PRO</h1>
						<h3>Сервис сбора отзывов, управления репутацией и отсеивания негатива</h3>
						<div className="icons">
							<div className="icon">
								<CiStar />
								<p>
									<small>Получайте только хорошие отзывы и увеличивайте рейтинг</small>
								</p>
							</div>
							<div className="icon">
								<IoLocationOutline />
								<p>
									<small>Повышайте позиции в поиске и на картах опережая конкурентов</small>
								</p>
							</div>
						</div>
						<Link className="demo-button" to="/totam/" target="_blank">
							<strong>Посмотреть демо</strong>
						</Link>
					</div>
				</div>
			</div>
			<Box sx={{ padding: 4, maxWidth: "100%", margin: "auto" }}>
				<Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: "bold", color: "#333" }}>
					Почему OTZO.PRO нужен вам прямо сейчас?
				</Typography>
				<Grid container spacing={2} justifyContent="center">
					{reasons.map((reason, index) => (
						<Grid item {...reason.size} key={index} sx={{ display: "flex" }}>
							<Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column", backgroundImage: reason.gradient }}>
								<CardContent sx={{ flexGrow: 1 }}>
									{reason.icon}
									<Typography variant="h5" component="h2" sx={{ lineHeight: 1, marginBottom: "10px" }}>
										{reason.title}
									</Typography>
									<Typography variant="body2">{reason.description}</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			<div className="widget-description">
				<h2>Виджет на сайт</h2>
				<p>Красивый и элегантный виджет на ваш сайт замотивирует клиента оставить отзыв</p>
				<Link to="/totam/" target="_blank" className="widget-wrapper">
					<img src={blackWidget} alt="Чёрный виджет" />
					<img src={whiteWidget} alt="Белый виджет" />
				</Link>
			</div>

			<div className="qr-design">
				<h2>Дизайнерский QR-код</h2>
				<p>Замените множество QR-кодов одним красивым и лаконичным, сделанным специально для вас!</p>
				<div className="qr-carousel-container">
					<Carousel>
						{images.map((image, i) => (
							<Paper key={i} elevation={0} style={{ backgroundColor: "transparent" }}>
								<img src={image} alt={`Дизайнерский QR-код ${i + 1}`} style={{ width: "100%", height: "auto" }} />
							</Paper>
						))}
					</Carousel>
				</div>
			</div>

			<Box sx={{ padding: 4, backgroundColor: "#D4DAFF" }}>
				<Typography
					variant="h4"
					gutterBottom
					align="center"
					sx={{ fontWeight: "bold", color: "#333" }} // или можно использовать числовое значение, например fontWeight={700}
				>
					OTZO.PRO в цифрах
				</Typography>
				<Typography variant="subtitle1" align="center" gutterBottom sx={{ marginBottom: 4 }}>
					Статистика собрана на основании данных по использованию сервиса нашими клиентами
				</Typography>
				<Grid container spacing={4} justifyContent="center">
					{stats.map((stat, index) => (
						<Grid item xs={12} sm={6} md={3} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<CircularProgressWithRoundCaps value={stat.value} size={150} thickness={15} />
							<Typography variant="h6" align="center" sx={{ fontWeight: "600", marginTop: 2 }}>
								{stat.label}
							</Typography>
							<Typography variant="body1" align="center" sx={{ fontWeight: "light", marginTop: 1 }}>
								{stat.description}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Box>
			<div className="pricing">
				<h2>Сколько стоит?</h2>
				<p>Отличный имидж и безупречный рейтинг бесценны.</p>
				<p>Стоимость сервиса OTZO.PRO</p>
				<div className="card">
					<p>Бизнес</p>
					<p className="price">6 000 ₽</p>
					<p>за год</p>
					<ul>
						<li>Фильтрация негатива, с уведомлением в Телеграм</li>
						<li>Установка до 6-ти кнопок для отзывов</li>
						<li>Виджет для вашего сайта</li>
						<li>Профессиональный дизайн QR-кода</li>
						<li>Целый год поддержки и обслуживания</li>
					</ul>
					<button className="main-submit-button" onClick={scrollToContactForm}>
						Оставить заявку
					</button>
				</div>
			</div>

			<div id="contact-form" className="contact-form">
				<div className="contact-overlay">
					<div className="contact-content">
						<h1>Оставьте заявку</h1>
						<p>Мы свяжемся с вами в рабочее время и все настроим</p>
						<MuiTelInput className="main-phone-input" defaultCountry="RU" forceCallingCode focusOnSelectCountry langOfCountryName="ru" flagSize="small" variant="outlined" placeholder="Номер телефона" value={phoneNumber} onChange={handleChangePhoneNumber} />
						<TextField
							className="main-name-input"
							name="name"
							value={name}
							onChange={handleChangeName}
							placeholder="Имя"
							variant="outlined"
							InputLabelProps={{
								shrink: false,
							}}
							fullWidth
						/>
						<button className="main-submit-button" type="submit" onClick={handleSubmit}>
							Оставить заявку
						</button>

						<div className="divider">
							<span className="divider-text">
								<strong>ИЛИ</strong>
							</span>
						</div>

						<button className="main-submit-button" type="button" onClick={openTelegramBot}>
							Оставить заявку через Telegram
						</button>
					</div>
				</div>
			</div>

			<div className="contact-info">
				<h2>Контакты для связи:</h2>
				<p>
					<a href="tel:+79785825800">
						<small>+7 (978) 582-58-00</small>
					</a>
				</p>
				<p>
					<a href="mailto:info@totmarketing.ru">
						<small>info@totmarketing.ru</small>
					</a>
				</p>
				<div class="icon-container">
					<a href="https://wa.me/79785825800" target="_blank">
						<IoLogoWhatsapp />
					</a>
					<a href="viber://chat?number=%2B79785825800" target="_blank">
						<FaViber />
					</a>
					<a href="tel:+79785825800">
						<FaPhoneAlt />
					</a>
					<a href="mailto:info@totmarketing.ru">
						<IoMdMail />
					</a>
				</div>
			</div>
			<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
				<MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
					{snackbarMessage}
				</MuiAlert>
			</Snackbar>
		</div>
	)
}

export default MainPage
